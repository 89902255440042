import React, { FunctionComponent } from 'react';
import { ArticleRecord } from '../types/dato.types';
import Article from '../components/molecules/Article/Article';
import Layout from '../components/molecules/Layout';
import { getArticlesHomePage } from 'src/lib/dato';

interface Props {
  articles: ArticleRecord[];
}

const PageHome: FunctionComponent<Props> = ({ articles }) => {
  return (
    <Layout>
      <Article articles={articles} showOnHomepage />
    </Layout>
  );
};

export async function getStaticProps() {
  const articles = await getArticlesHomePage();
  return {
    props: { articles },
  };
}

export default PageHome;
