import React, { FunctionComponent, ReactNode } from 'react';
import Navigation from './Navigation/Navigation';
import Footer from './Footer/Footer';

interface Props {
  children?: ReactNode;
}

const Layout: FunctionComponent<Props> = ({ children }) => {
  return (
    <div>
      <Navigation />
      <div className="Content">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
