import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { ArticleRecord } from '../../../types/dato.types';

interface Props {
  articles: ArticleRecord[];
  showOnHomepage?: boolean;
}

const Article: FunctionComponent<Props> = ({ articles, showOnHomepage }) => {
  return (
    <div className="Article__Container">
      <div className="Article">
        {articles &&
          articles.map((article: ArticleRecord, i: number) => (
            <Link key={i} href={`/work/${article.slug}`}>
              <div
                style={{
                  border: '1px solid #f7f7f7',
                  width: 250,
                  height: 350,
                  backgroundImage: `url(${
                    showOnHomepage
                      ? article.imageHome?.url || article.image[0].url
                      : article.image[0].url
                  })`,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  cursor: 'pointer',
                  margin: 10,
                }}
              />
            </Link>
          ))}
      </div>
    </div>
  );
};

Article.defaultProps = {
  showOnHomepage: false,
};

export default Article;
