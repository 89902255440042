import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Logo from '../../../assets/logo.png';

const Navigation: FunctionComponent = () => {
  const router = useRouter();

  const handleActivePath = (pathname: string) => {
    return router.pathname == pathname ? 'Navigation__Active' : '';
  };

  return (
    <div className="Navigation">
      <h1>
        <Link href="/">
          <a title="Home">
            <img style={{ cursor: 'pointer' }} width="100" height="auto" src={Logo} />
          </a>
        </Link>
      </h1>
      <nav>
        <ul className="Navigation__Nav">
          <li className={handleActivePath('/')}>
            <Link href="/">
              <a title="Home">Home</a>
            </Link>
          </li>
          <li className={handleActivePath('/work')}>
            <Link href="/work">
              <a title="Work">Work</a>
            </Link>
          </li>
          <li className={handleActivePath('/about')}>
            <Link href="/about">
              <a title="About">About</a>
            </Link>
          </li>
          <li className={handleActivePath('/contact')}>
            <Link href="/contact">
              <a title="Contact">Contact</a>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation;
