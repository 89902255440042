import React, { FunctionComponent } from 'react';
import InstagramIcon from '../../../assets/svg/instagram.svg';

const Footer: FunctionComponent = () => {
  return (
    <footer className="Footer">
      <a target="_blank" href="https://www.instagram.com/sofiebeys/">
        <InstagramIcon />
        <span>Beys</span>
      </a>
    </footer>
  );
};

export default Footer;
